<template>
<!-- Navbar -->
 <header>
        <nav>
            <ul class="has-text-centered">
                <li><a href="#welcome">Welcome</a></li>
                <li><a href="#skills">Skills</a></li>
                <li><a href="#projects">Projects</a></li>
                <li><a href="#contact">Contact</a></li>
            </ul>
        </nav>

    </header>
 <!-- Navbar ends -->
</template>
<style>

</style>